<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1 mr-1">
          mecarehub
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Selamat datang di mecarehub 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan login terlebih dahulu
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="loginAuth"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group
                label="Password"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="authError"
                class="mt-1 mb-2"
              >
                <small
                  class="text-danger"
                >
                  {{ authError }}
                </small>
              </div>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                <span v-if="!loading">Masuk</span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { auth, db } from '@/firebase'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      authError: '',
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async loginAuth() {
      this.authError = ''
      this.loading = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          auth.signInWithEmailAndPassword(this.userEmail, this.password).then(async val => {
            await db.collection('mecarehub').where('email', '==', this.userEmail.toLowerCase()).where('status', '==', 1).get()
              .then(querySnapshot => {
                if (!querySnapshot.empty) {
                  querySnapshot.forEach(async doc => {
                    let idl
                    if (doc.data().role === 'wakil_leader') {
                      idl = doc.data().access.mitra.leader_id
                    } else {
                      idl = doc.id
                    }
                    const userData = {
                      id: doc.id,
                      nama: doc.data().nama,
                      email: doc.data().email,
                      role: doc.data().role,
                      permission: doc.data().access,
                      leader_id: idl,
                    }
                    localStorage.setItem('userData', JSON.stringify(userData))

                    this.$router.push({ name: 'home' })
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Login Berhasil',
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                    })
                    this.loading = false
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Akun belum diaktifkan, harap hubungi pusat',
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                  this.loading = false
                }
              })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email atau password salah',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.authError = 'Email atau password salah'
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
